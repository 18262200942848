import { useEffect } from 'react'
import { useConnect, useDisconnect } from 'wagmi'

import { localConnector } from 'lib/wagmi'

interface UseAutoConnectProps {
  accessKey?: string
}

export const useAutoConnect = ({ accessKey }: UseAutoConnectProps) => {
  // Instantiate groupId here

  const { connect } = useConnect({
    connector: localConnector,
  })

  const { disconnect } = useDisconnect()

  useEffect(() => {
    // Hacky fix to force accessKey update outside of client
    if (accessKey) {
      void localConnector.setAccessKey(accessKey)
      connect()
    }

    return () => {
      disconnect()
    }
  }, [connect, disconnect, accessKey])
}
