import { Container, Flex, HStack, Image, Link, Text } from '@chakra-ui/react'

import sgCrest from 'assets/sg-crest.svg'

const GovernmentBannerOld = () => (
  <Flex alignItems="center" bg="#eee" w="100%" h="30px">
    <Container maxW="96em">
      <Link href="https://www.gov.sg">
        <HStack spacing="12px">
          <Image
            src={sgCrest}
            boxSize="20px"
            alt="Singapore Crest"
            loading="eager"
          />
          <Text fontSize="sm" color="gray.800">
            A Singapore Government Website
          </Text>
        </HStack>
      </Link>
    </Container>
  </Flex>
)
export default GovernmentBannerOld
