import { useContractWrite, usePrepareContractWrite } from 'wagmi'

import randomAbi from 'constants/abi/pbm.json'
import { PBM_ADDRESS } from 'constants/config'
import { useToast } from 'hooks/useToast'
import { parseToBigNumber } from 'utils/web3'
import { Logger } from 'ethers/lib/utils'

interface UseConfirmProps {
  address: string
  amount: string
}

// Composed hooks for handling transactions
export const useConfirm = ({ address, amount }: UseConfirmProps) => {
  const { showToast } = useToast()

  // Hook for preparing contract write
  // This is where gas estimations is done, and transactions are created
  const { config: txConfig, isError: isPrepareError } = usePrepareContractWrite(
    {
      addressOrName: PBM_ADDRESS,
      contractInterface: randomAbi.abi,
      functionName: 'redeem',
      args: [address, parseToBigNumber(amount)],
      onError: (err: any) => {
        showToast({
          type: 'info',
          header: 'Gas estimation failed.',
          message: `This transaction is not allowed. ${err.reason} `,
        })
      },
    },
  )

  // Hook for committing transaction
  // This is where a transaction is signed, and is being sent to network
  const {
    write,
    data: transactionData,
    isLoading: isSignatureLoading,
  } = useContractWrite({
    ...txConfig,
    onError: (err: any) => {
      if (err.code === Logger.errors.INSUFFICIENT_FUNDS) {
        return showToast({
          type: 'info',
          header: 'Insufficient gas',
          message:
            'There is insufficient gas to make the transaction. Please contact support for assistance',
        })
      }

      return showToast({
        type: 'info',
        header: 'Transaction cannot be made',
        message: `An unexpected error has occured. Please contact support if issue persists`,
      })
    },
  })

  return {
    isPrepareError,
    isSignatureLoading,
    transactionData,
    write,
  }
}
