import { BiRightArrowAlt } from 'react-icons/bi'
import {
  Button,
  HStack,
  LinkBox,
  LinkOverlay,
  Stat,
  StatLabel,
  StatNumber,
  Tag,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useBalance } from 'wagmi'

import { BLOCK_EXPLORER } from 'constants/config'
import { formatBigNumberToDisplay } from 'utils/web3'

interface DebugCardProps {
  token: string
  clientAddress: string
}

const TokenCard = ({ token, clientAddress }: DebugCardProps) => {
  const { isFetched, data } = useBalance({
    token,
    addressOrName: clientAddress,
    staleTime: 2000,
  })

  if (!isFetched || !data) {
    return null
  }

  return (
    <LinkBox
      backgroundColor="white"
      borderRadius="8px"
      borderColor="neutral.100"
      paddingX="20px"
      paddingY="12px"
      boxShadow="0px 0px 10px rgba(216, 222, 235, 0.5)"
      boxSizing="border-box"
    >
      <VStack spacing="1px" align="flex-start">
        <Text textStyle="h5" color="primary.700">
          {data.symbol}
        </Text>
        <Tag noOfLines={2} size="sm" maxW="250px">
          {token}
        </Tag>
      </VStack>
      <HStack mt="16px" justify="space-between" align="flex-end">
        <Stat>
          <StatLabel>Balance ({data.decimals})</StatLabel>
          <StatNumber>${formatBigNumberToDisplay(data.value)}</StatNumber>
        </Stat>
        <LinkOverlay href={`${BLOCK_EXPLORER}/address/${token}`}>
          <Button borderRadius="20px" rightIcon={<BiRightArrowAlt />}>
            <Text textStyle="caption2">See Token</Text>
          </Button>
        </LinkOverlay>
      </HStack>
    </LinkBox>
  )
}

export default TokenCard
