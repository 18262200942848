import moment from 'moment-timezone'

export function formatDateString(dateString: null): null
export function formatDateString(dateString: string): string
export function formatDateString(dateString: string | null) {
  if (dateString === null) {
    return null
  }
  return moment(dateString, 'X').format('DD MMM YYYY')
}
