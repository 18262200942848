import {
  GroupedVoucherResponse,
  ListRedemptionsResponse,
} from 'typings/voucher'

import baseConfig from './baseConfig'

export function fetchGroupById(id: string): Promise<GroupedVoucherResponse> {
  // console.log(`GET fetchGroupById /vouchers/groups/${id}`)
  return baseConfig.get(`/public/vouchers/groups/${id}`).then((res) => res.data)
}

export function fetchListOfRedemptions(
  fromAddress: string,
): Promise<ListRedemptionsResponse> {
  return baseConfig
    .get(`/token/pbm/redemptions`, {
      params: {
        from: fromAddress,
      },
    })
    .then((res) => res.data)
}
