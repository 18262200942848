import { useState } from 'react'
import { IoMdSend } from 'react-icons/io'
import {
  HStack,
  IconButton,
  InputGroup,
  InputLeftAddon,
  NumberInput,
  NumberInputField,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'

import { RedemptionDrawerProvider } from 'features/redemption/context/RedemptionDrawerContext'
import RedemptionDrawer from 'features/redemption/RedemptionDrawer'

interface PaymentProps {
  maxAmountSpendable: number
}

const Payment = ({ maxAmountSpendable }: PaymentProps) => {
  const [amountForRedemption, setAmountForRedemption] = useState('')

  const {
    onClose: closeRedemptionDrawer,
    onOpen: openRedemptionDrawer,
    isOpen: isRedemptionDrawerOpen,
  } = useDisclosure({ defaultIsOpen: false })

  return (
    <>
      <VStack alignItems="center" w="100%">
        <HStack alignItems="stretch" spacing="16px" width="80%" marginTop="8px">
          <InputGroup>
            <InputLeftAddon>$</InputLeftAddon>
            <NumberInput
              backgroundColor="white"
              max={maxAmountSpendable}
              min={0.01}
              precision={2}
              onChange={(valueString) => setAmountForRedemption(valueString)}
              w="100%"
            >
              <NumberInputField
                borderLeftRadius={0}
                height="100%"
                placeholder="Enter amount"
              />
            </NumberInput>
          </InputGroup>

          <IconButton
            variant="outline"
            onClick={openRedemptionDrawer}
            isDisabled={!amountForRedemption}
            colorScheme="primary"
            aria-label="Send"
            icon={<IoMdSend />}
            minH="100%"
          />
        </HStack>
      </VStack>
      <RedemptionDrawerProvider>
        <RedemptionDrawer
          isOpen={isRedemptionDrawerOpen}
          onClose={closeRedemptionDrawer}
          campaignName="Placeholder name"
          amount={amountForRedemption}
        />
      </RedemptionDrawerProvider>
    </>
  )
}

export default Payment
