import { useState } from 'react'
import { BiLeftArrowAlt } from 'react-icons/bi'
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Icon,
  VStack,
} from '@chakra-ui/react'
import { MerchantDto } from 'typings/voucher'

import ConfirmationModalV2 from 'features/redemption/components/Confirmation/ConfirmationModalV2'
import Offer from 'features/redemption/components/Offer/Offer'
import { useRedemptionDrawerContext } from 'features/redemption/context/RedemptionDrawerContext'

enum Step {
  'offer',
  'confirm',
}

// TODO: Move over to a proper merchant context
const NULL_MERCHANT = {
  shopName: '',
  walletAddress: '',
}

interface RedemptionDrawerProps {
  isOpen: boolean
  onClose: () => void
  amount: string

  campaignName: string
  campaignExpiresAt?: string | null
  campaignQrLogoUrl?: string | null
  step?: Step
}

const RedemptionDrawer = ({
  isOpen,
  onClose,
  campaignName,
  amount,
  step = Step.offer,
}: RedemptionDrawerProps) => {
  // TODO: Consider step manager/context if state gets more complicated
  const [currStep, setCurrStep] = useState(step)
  const [recipient, setRecipient] = useState(NULL_MERCHANT)

  const { isDisableReturn } = useRedemptionDrawerContext()

  const handleAcceptOffer = (recipient: MerchantDto) => {
    setRecipient(recipient)
    setCurrStep(Step.confirm)
  }

  const handleClose = () => {
    // Hacky state cleanup routine
    setCurrStep(Step.offer)
    setRecipient(NULL_MERCHANT)
    onClose()
  }

  return (
    <Drawer
      isFullHeight
      autoFocus={false}
      blockScrollOnMount={true}
      isOpen={isOpen}
      onClose={onClose}
      placement="bottom"
    >
      <DrawerOverlay />
      <DrawerContent maxH={'-webkit-fill-available'}>
        <DrawerBody backgroundColor="primary.100" height="100%" padding="16px">
          <VStack alignItems="center" minH={'100%'}>
            <VStack
              flex={1}
              maxWidth={'512px'}
              alignItems="stretch"
              flexShrink="0"
              width={'100%'}
              justifyContent="space-between"
            >
              {currStep === Step.offer && (
                <Offer
                  campaignName={campaignName}
                  onClose={handleClose}
                  onAccept={handleAcceptOffer}
                  amount={amount}
                />
              )}
              {currStep === Step.confirm && (
                <ConfirmationModalV2
                  senderName="You"
                  recipientName={recipient.shopName}
                  recipientAddress={recipient.walletAddress}
                  amount={amount}
                />
              )}

              <VStack bottom="0">
                {!isDisableReturn && (
                  <Button
                    colorScheme="primary"
                    variant="outline"
                    width="100%" // TODO: investigate support for isFullWidth in chakra 2.x
                    padding="16px"
                    leftIcon={<Icon as={BiLeftArrowAlt} boxSize={'24px'} />}
                    onClick={handleClose}
                  >
                    Back
                  </Button>
                )}
              </VStack>
            </VStack>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default RedemptionDrawer
