import { Button, Image, StackItem, Text, VStack } from '@chakra-ui/react'

import errorGraphic from 'assets/x-circle-solid-danger.svg'

const ErrorPrompt = () => {
  return (
    <VStack w="100%" alignItems="center" spacing="2px">
      <Image src={errorGraphic} />
      <Text textAlign="center" textStyle="body1" color="neutral.700">
        An unexpected error has occurred. There was a failure retrieving your
        wallet.
      </Text>
      <StackItem>
        <Button marginTop="20px">Contact Support</Button>
      </StackItem>
    </VStack>
  )
}

export default ErrorPrompt
