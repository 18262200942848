import { Center, Image, Link, Text, VStack } from '@chakra-ui/react'
import { useAccount, useWaitForTransaction } from 'wagmi'

import successLogo from 'assets/bx-check-circle-outline.svg'
import { BLOCK_CONFIRMATIONS, BLOCK_EXPLORER } from 'constants/config'
import { formatBigNumberToString } from 'utils/web3'

import ResultLoading from 'features/redemption/components/Confirmation/components/ResultLoading'
import { useListRedemptions } from 'hooks'

interface ResultProps {
  txHash: string
  recipientName: string
  amount: string
}

const Result = ({ txHash, recipientName, amount }: ResultProps) => {
  const { address } = useAccount()

  const { refetch } = useListRedemptions({
    fromAddress: address,
  })

  const { isSuccess, data } = useWaitForTransaction({
    hash: txHash,
    confirmations: BLOCK_CONFIRMATIONS,
    onSuccess: () => refetch(),
  })

  return isSuccess && data ? (
    <Center flex={1}>
      <VStack color="primary.600" paddingX="60px" spacing="32px">
        <Image src={successLogo} />

        <VStack spacing="12px">
          <Text textStyle="h5" textAlign="center">
            You have paid ${amount} to {recipientName}.
          </Text>
          <Text textStyle="body2" textAlign="center" color="neutral.600">
            You may view the transaction on the{' '}
            <Link
              textDecoration="underline"
              href={`${BLOCK_EXPLORER}/tx/${data.transactionHash}`}
            >
              explorer
            </Link>
            .
          </Text>
          <Text textStyle="legal" textAlign="center" color="neutral.600">
            Gas guzzled: {formatBigNumberToString(data.gasUsed)}. Gas unit at:{' '}
            {formatBigNumberToString(data.effectiveGasPrice)}.
          </Text>
        </VStack>
      </VStack>
    </Center>
  ) : (
    <ResultLoading />
  )
}

export default Result
