import { datadogRum } from '@datadog/browser-rum'
import { DatadogUser } from 'typings/datadog'

import { DATADOG_SAMPLE_RATE } from 'constants/config'

const VOUCHER_ID_REGEX = /\/[a-zA-Z0-9]{30}/

export function initMonitoring() {
  if (
    !process.env.REACT_APP_DATADOG_APPLICATION_ID ||
    !process.env.REACT_APP_DATADOG_CLIENT_TOKEN
  ) {
    return
  }

  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'cbdc-voucher',
    env: process.env.REACT_APP_ENV || process.env.NODE_ENV,

    // Specify a version number to identify the deployed version of your application in Datadog
    version: '0.0.1',
    sampleRate: DATADOG_SAMPLE_RATE,
    sessionReplaySampleRate: DATADOG_SAMPLE_RATE,
    trackInteractions: true,
    trackResources: true,
    beforeSend: (event) => {
      // Filters out voucher ids from view for privacy
      event.view.url = event.view.url.replace(
        VOUCHER_ID_REGEX,
        '/VOUCHER_REDACTED',
      )

      // Filters out referrer with voucher IDs
      event.view.referrer = event.view.referrer?.replace(
        VOUCHER_ID_REGEX,
        '/VOUCHER_REDACTED',
      )

      // Excludes resources that have sensitive identifiers
      if (
        event.type === 'resource' &&
        event.resource.url.search(VOUCHER_ID_REGEX) !== -1
      ) {
        return false
      }
    },
  })

  datadogRum.startSessionReplayRecording()
}

export function setDatadogUser({ name }: DatadogUser) {
  datadogRum.setUser({
    name,
  })
}
