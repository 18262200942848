import { PBMToken } from 'typings/typechain'
import { useContract, useProvider, useQuery } from 'wagmi'

import pbmAbi from 'constants/abi/pbm.json'

interface UseCampaignInfoParams {
  tokenAddress: string
}

// TODO: Move to separate service folder
const getCampaignInfo = async (contract: PBMToken) => {
  return {
    name: await contract.name(),
    contractExpiry: await contract.contractExpiry(),
    isPaused: await contract.paused(),
  }
}

export const useCampaignInfo = ({ tokenAddress }: UseCampaignInfoParams) => {
  const provider = useProvider()

  const contract = useContract<PBMToken>({
    addressOrName: tokenAddress,
    contractInterface: pbmAbi.abi,
    signerOrProvider: provider,
  })

  return useQuery(['campaign'], () => getCampaignInfo(contract))
}
