import { Trans, useTranslation } from 'react-i18next'
import { BiBuildings, BiMessageSquareDetail, BiPhone } from 'react-icons/bi'
import { Link, Text, VStack } from '@chakra-ui/react'
import { ContactLink } from 'typings/config'

import { formatContactNumberForDisplay } from 'utils/contactNumber'

import HelpInfoEntry from '../HelpInfoEntry'

type OrganiserContactSectionProps = {
  campaignOrganiserName?: string | null
  campaignOrganiserLocation?: string | null
  campaignOrganiserContactLink?: ContactLink | null
  campaignOrganiserFeedbackUrl?: string | null
  campaignOrganiserEmail?: string | null
}

const OrganiserContactSection = ({
  campaignOrganiserName,
  campaignOrganiserLocation,
  campaignOrganiserContactLink,
  campaignOrganiserFeedbackUrl,
  campaignOrganiserEmail,
}: OrganiserContactSectionProps) => {
  const { t } = useTranslation('help', { keyPrefix: 'contact' })
  return (
    <VStack align="stretch" spacing="24px">
      <Text textStyle="h4" color="primary.700">
        {t('title')}
      </Text>
      <VStack align="stretch" spacing="16px">
        <Text textStyle="subhead1" color="neutral.800" paddingBottom="8px">
          {campaignOrganiserName || 'Campaign Organiser'}
        </Text>
        {!!campaignOrganiserFeedbackUrl && (
          <HelpInfoEntry icon={BiMessageSquareDetail}>
            <Text textStyle="body1">
              <Trans t={t} i18nKey="link">
                Submit enquiry
                <Link
                  color="primary.700"
                  href={`campaignOrganiserFeedbackUrl`}
                  isExternal
                >
                  here
                </Link>
              </Trans>
            </Text>
          </HelpInfoEntry>
        )}
        {!!campaignOrganiserContactLink && (
          <HelpInfoEntry icon={BiPhone}>
            <Text textStyle="body1">
              Whatsapp {`  `}
              <Link
                color="primary.700"
                textDecoration="none"
                href={campaignOrganiserContactLink.link}
                isExternal
              >
                {formatContactNumberForDisplay(
                  campaignOrganiserContactLink.number,
                )}
              </Link>
            </Text>
          </HelpInfoEntry>
        )}

        {!!campaignOrganiserEmail && (
          <HelpInfoEntry icon={BiMessageSquareDetail}>
            <Text textStyle="body1">
              Email{`  `}
              <Link
                color="primary.700"
                href={`mailto:${campaignOrganiserEmail}`}
                isExternal
              >
                {campaignOrganiserEmail}
              </Link>
            </Text>
          </HelpInfoEntry>
        )}
        {!!campaignOrganiserLocation && (
          <HelpInfoEntry icon={BiBuildings}>
            <Text textStyle="body1">{campaignOrganiserLocation}</Text>
          </HelpInfoEntry>
        )}
      </VStack>
    </VStack>
  )
}

export default OrganiserContactSection
