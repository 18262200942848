import { BiRightArrowAlt } from 'react-icons/bi'
import {
  Box,
  Button,
  ButtonProps,
  HStack,
  Icon,
  Link,
  LinkProps,
  Text,
  VStack,
} from '@chakra-ui/react'

type HelpDrawerLearnMorebuttonProps = {
  image: string
  title: string
  description: string
  href: LinkProps['href']
  imagePosition: ButtonProps['backgroundPosition']
} & LinkProps

const HelpDrawerLearnMorebutton = ({
  image,
  imagePosition,
  title,
  description,
  href,
  ...linkProps
}: HelpDrawerLearnMorebuttonProps) => {
  return (
    <Link
      textDecoration="none"
      _hover={{ textDecoration: 'none' }}
      href={href}
      isExternal
      {...linkProps}
    >
      <Button
        width="100%"
        paddingLeft="20px"
        background="white"
        backgroundImage={image}
        backgroundPosition={imagePosition}
        backgroundRepeat="no-repeat"
        borderRadius="8px"
        filter="auto"
        dropShadow="var(--chakra-shadows-small)"
        paddingY="20px"
        variant="unstyled"
      >
        <HStack>
          <VStack
            align="start"
            width="calc(100% - 104px)"
            textAlign="start"
            spacing="8px"
          >
            <Text
              textStyle="h5"
              maxWidth="100%"
              color="primary.500"
              whiteSpace="pre-wrap"
            >
              {title}
            </Text>
            <Box display="inline" width="100%">
              <Text
                textStyle="body2"
                maxWidth="100%"
                color="neutral.800"
                whiteSpace="pre-wrap"
              >
                {description}{' '}
                <Icon
                  as={BiRightArrowAlt}
                  boxSize="16px"
                  verticalAlign="middle"
                  color="neutral.800"
                />
              </Text>
            </Box>
          </VStack>
        </HStack>
      </Button>
    </Link>
  )
}

export default HelpDrawerLearnMorebutton
