import { HStack, Text, VStack } from '@chakra-ui/react'

interface StepHeaderProps {
  step: number
  title: string
}

const StepHeader = ({ step, title }: StepHeaderProps) => {
  return (
    <HStack
      borderTopRadius={'16px'}
      backgroundColor="primary.200"
      alignItems="center"
      justifyContent="space-between"
      padding={'14px 28px'}
    >
      <VStack spacing="3px" alignItems="flex-start" color="primary.600">
        <Text textStyle="legal" color="primary.500">
          Step {step} of 2
        </Text>
        <Text textStyle="h4">{title}</Text>
      </VStack>
    </HStack>
  )
}

export default StepHeader
