import { useQuery } from 'wagmi'

import { fetchGasInfo } from 'services/MaticService/MaticApi'

interface UseGasInfoParams {
  onError?: (err: unknown) => void
}

export const useGasInfo = ({ onError }: UseGasInfoParams) => {
  return useQuery(['gasReport'], () => fetchGasInfo(), {
    refetchInterval: 2_000,
    onError,
  })
}
