import { ComponentStyleConfig } from '@chakra-ui/react'

export const Link: ComponentStyleConfig = {
  baseStyle: {},
  variants: {
    underline: {
      textDecoration: 'underline',
    },
  },
}
