import { ComponentStyleConfig } from '@chakra-ui/react'

export const Divider: ComponentStyleConfig = {
  baseStyle: {
    borderColor: 'neutral.300',
    opacity: 1,
  },
  variants: {
    dotted: {
      borderColor: 'primary.200',
      borderBottomWidth: '2px', // chakra uses borderBottom for thicknesss,
      borderStyle: 'dashed',
    },
  },
}
