import React, { ReactNode, useMemo } from 'react'
import {
  CloseButton,
  HStack,
  Icon,
  Text,
  useMultiStyleConfig,
  VStack,
} from '@chakra-ui/react'

import { ReactComponent as BxsCheckCircleInfo } from 'assets/bxs-check-circle-info.svg'
import { ReactComponent as BxsCheckCircleSuccess } from 'assets/bxs-check-circle-success.svg'

export type ToastProps = {
  header: ReactNode
  type: 'success' | 'info' | 'danger'
  onClose: () => void
  message?: ReactNode
}

const Toast = ({ header, message, type, onClose }: ToastProps) => {
  const styles = useMultiStyleConfig('Toast', { variant: type })

  const IconToRender = useMemo(
    () => (type === 'success' ? BxsCheckCircleSuccess : BxsCheckCircleInfo),
    [type],
  )

  return (
    <HStack sx={styles.container}>
      <Icon as={IconToRender} sx={styles.icon} />
      <VStack sx={styles.content}>
        <Text sx={styles.header}>{header}</Text>
        {message && <Text sx={styles.message}>{message}</Text>}
      </VStack>
      <CloseButton sx={styles.close} onClick={onClose} />
    </HStack>
  )
}
export default Toast
