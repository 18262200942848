import { BigNumber, FixedNumber } from 'ethers'
import { formatUnits, parseUnits } from 'ethers/lib/utils'
import { Chain } from 'wagmi'

import { PBM_DECIMALS } from 'constants/config'

export function formatBigNumberToString(bigNumber: BigNumber) {
  return formatUnits(bigNumber, PBM_DECIMALS)
}

export function parseToBigNumber(value: string) {
  return parseUnits(value, PBM_DECIMALS)
}

export function formatBigNumberToDisplay(bigNumber: BigNumber) {
  return FixedNumber.fromValue(bigNumber, PBM_DECIMALS).round(2).toString()
}

export function getAnkrRpcUrl(currentChain: Chain) {
  if (currentChain.id === 137) {
    return 'https://rpc.ankr.com/polygon'
  }
  return 'https://rpc.ankr.com/polygon_mumbai'
}
