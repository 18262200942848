import { useCallback } from 'react'
import { StackDivider, VStack } from '@chakra-ui/react'
import _ from 'lodash'

import { useGroupVoucher, useListRedemptions } from 'hooks'
import { formatBigNumberToDisplay } from 'utils/web3'

import TransactionCard from 'features/debugger/components/Transaction/TransactionCard'
import TransactionEmptyCard from 'features/debugger/components/Transaction/TransactionEmptyCard'
import DebugTemplate from 'features/debugger/templates/DebugTemplate'
import { ListingProps } from 'features/debugger/types'

const TransactionListing = ({ clientAddress }: ListingProps) => {
  const { data, isLoading, isError } = useListRedemptions({
    fromAddress: clientAddress,
  })

  const { data: groupData } = useGroupVoucher()

  const filterMerchants = useCallback(
    (address: string) => {
      if (groupData?.mappedMerchants[address]) {
        return groupData.mappedMerchants[address].shopName
      }
      return 'NO_SHOP_NAME'
    },
    [groupData?.mappedMerchants],
  )

  return (
    <DebugTemplate title="Transactions">
      {_.isEmpty(data) ? (
        <TransactionEmptyCard isLoading={isLoading} isError={isError} />
      ) : (
        <VStack
          alignItems="stretch"
          spacing="0px"
          divider={<StackDivider borderColor="neutral.200" />}
        >
          {data?.map((transaction) => (
            <TransactionCard
              key={transaction.transactionHash}
              transactionHash={transaction.transactionHash}
              timestamp={transaction.timestamp}
              transactionValue={formatBigNumberToDisplay(
                transaction.redemptionValue,
              )}
              name={filterMerchants(transaction.recipientAddress)}
            />
          ))}
        </VStack>
      )}
    </DebugTemplate>
  )
}

export default TransactionListing
