import { ScaleLoader } from 'react-spinners'
import { Alert, AlertIcon, Center } from '@chakra-ui/react'

import { colors } from 'theme/foundations/colors'

interface TransactionErrorProps {
  isLoading: boolean
  isError: boolean
}

const TransactionEmptyCard = ({
  isLoading,
  isError,
}: TransactionErrorProps) => {
  if (isLoading) {
    return (
      <Center>
        <ScaleLoader color={colors.primary['600']} />
      </Center>
    )
  }

  if (isError) {
    return (
      <Alert colorScheme="primary">
        <AlertIcon />
        This feature is coming soon. Come back again later!
      </Alert>
    )
  }

  // Defaults to card where no transactions have yet been made.
  return (
    <Alert colorScheme="secondary">
      <AlertIcon />
      Your transactions will appear here when they have been made.
    </Alert>
  )
}

export default TransactionEmptyCard
