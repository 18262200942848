import React from 'react'
import { useTranslation } from 'react-i18next'
import { BiChevronDown, BiChevronUp, BiGlobe } from 'react-icons/bi'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useMultiStyleConfig,
} from '@chakra-ui/react'

import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from 'constants/translation'
import { changeLanguage } from 'utils/i18n'

interface LanguageSelectorProps {
  color?: 'dark' | 'light'
}
// TODO: Move over to components, this shouldn't be nested in a feature like this
// TODO: Implement theming for light vs dark
const LanguageSelector = ({ color = 'dark' }: LanguageSelectorProps) => {
  const { i18n } = useTranslation('header')
  // Get selected locale information (label). If it is not found, then
  // default locale (en) will be assumed to be selected.
  const selectedLocale =
    SUPPORTED_LOCALES.find(
      // Get base language from locale
      (locale) => locale.code === i18n?.language?.substring(0, 2), // en-US -> en
    ) || DEFAULT_LOCALE
  const history = useHistory()

  const styles = useMultiStyleConfig('LanguageSelector', { variant: color })

  return (
    // Note: Wrapped in a box due to https://github.com/chakra-ui/chakra-ui/issues/3440
    <Box>
      <Menu autoSelect={false} matchWidth={true} offset={[0, 0]}>
        {({ isOpen }) => (
          <>
            <MenuButton
              as={Button}
              sx={styles.button}
              data-dd-action-name="Change Language button"
              iconSpacing={'9.5px'}
              leftIcon={<Icon as={BiGlobe} />}
              rightIcon={
                <Icon
                  as={isOpen ? BiChevronUp : BiChevronDown}
                  sx={styles.icon}
                />
              }
            >
              <Text sx={styles.label} id={'language-select-dropdown-button'}>
                {selectedLocale.label}
              </Text>
            </MenuButton>
            <MenuList sx={styles.dropdown} rootProps={{ width: '100%' }}>
              {SUPPORTED_LOCALES.map((locale) => (
                <MenuItem
                  key={locale.code}
                  sx={styles.item}
                  aria-label={locale.code}
                  backgroundColor={
                    selectedLocale.code === locale.code ? 'primary.200' : 'none'
                  }
                  isFocusable={false}
                  onClick={() => changeLanguage(locale.code, history)}
                >
                  {locale.label}
                </MenuItem>
              ))}
            </MenuList>
          </>
        )}
      </Menu>
    </Box>
  )
}

export default LanguageSelector
