import { Trans, useTranslation } from 'react-i18next'
import { HStack, Icon, Link, Text, VStack } from '@chakra-ui/react'

import { ReactComponent as BxErrorCircle } from 'assets/bx-error-circle.svg'
import { formatContactNumberForDisplay } from 'utils/contactNumber'

// TODO: Generalise. Currently texts contain PA specific information
interface GroupInactiveCampaignProps {
  campaignOrganiserContactNumber?: string
}

const GroupPageInactiveCampaign = ({
  campaignOrganiserContactNumber,
}: GroupInactiveCampaignProps) => {
  const { t } = useTranslation('alerts', {
    keyPrefix: 'inactive',
  })

  return (
    <VStack align={'flex-start'} color={'neutral.800'} spacing={'0.5em'}>
      <HStack align={'center'} color={'primary.500'} spacing={'0.5em'}>
        <Icon as={BxErrorCircle} boxSize={'24px'} />
        <Text textStyle={'h4'}>{t('campaign_inactive_header')}</Text>
      </HStack>
      <Text textStyle={'body1'} marginTop={'0.5em'}>
        Voucher(s) cannot be used as campaign is temporarily inactive. Please
        check with the campaign organiser for more information.
      </Text>
    </VStack>
  )
}

export default GroupPageInactiveCampaign
