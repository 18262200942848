import { ReactNode } from 'react'
import { Center, HStack, Image, Text, VStack } from '@chakra-ui/react'

import redeemLogo from 'assets/redeem-logo.svg'
import LanguageSelector from 'components/LanguageSelector'

import errorPageGraphic from '../../assets/error-page-graphic.svg'
import Header from '../basic/Header'

interface ErrorTemplateProps {
  errorObj: {
    title: string
    content: ReactNode
  }
}

// Presentational component for error states
const ErrorTemplate = ({ errorObj }: ErrorTemplateProps) => {
  return (
    <>
      <Header
        campaignDescription={errorObj.title}
        campaignTitle={errorObj.title}
      />
      <Center flexGrow={1} width="100%" background="white" id="error-template">
        <VStack
          align="stretch"
          justify="space-between"
          width="100%"
          maxWidth="512px"
          height="100%"
          paddingTop="32px"
          paddingBottom="0"
          paddingX="24px"
          spacing="52px"
        >
          <VStack align="stretch" spacing="20px">
            <HStack align="center" justify="space-between" paddingX="8px">
              <Image width="8rem" height="1.5rem" src={redeemLogo} />
              <LanguageSelector color="light" />
            </HStack>
            <VStack align="stretch" spacing="12px">
              <Text as="header" textStyle="h4" color="neutral.900">
                {errorObj.title}
              </Text>
              <Text textStyle="body1" color="neutral.800">
                {errorObj.content}
              </Text>
            </VStack>
          </VStack>
          <Image alt="error page graphic" src={errorPageGraphic} />
        </VStack>
      </Center>
    </>
  )
}

export default ErrorTemplate
