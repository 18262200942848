import { useEffect } from 'react'
import { SyncLoader } from 'react-spinners'
import { Center, Text, VStack } from '@chakra-ui/react'

import { colors } from 'theme/foundations/colors'

import { useRedemptionDrawerContext } from 'features/redemption/context/RedemptionDrawerContext'

const ResultLoading = () => {
  const { setDisableReturn } = useRedemptionDrawerContext()

  useEffect(() => {
    setDisableReturn(true)
    return () => {
      setDisableReturn(false)
    }
  }, [])

  return (
    <Center flex={1}>
      <VStack color="primary.600" paddingX="60px" spacing="32px">
        <SyncLoader color={colors.primary['600']} />

        <VStack spacing="12px">
          <Text textStyle="h5" textAlign="center">
            Verifying payment ...
          </Text>
        </VStack>
      </VStack>
    </Center>
  )
}

export default ResultLoading
