import { Button, Flex, VStack } from '@chakra-ui/react'

import { useConfirm } from 'hooks'

import ConfirmationModal from 'features/redemption/components/Confirmation/components/ConfirmationModal'
import Result from 'features/redemption/components/Confirmation/components/Result'
import StepHeader from 'features/redemption/components/RedemptionCard/components/StepHeader'

interface ConfirmationModalProps {
  senderName: string
  recipientName: string
  recipientAddress: string
  amount: string
}

const ConfirmationModalV2 = ({
  senderName,
  recipientName,
  recipientAddress,
  amount,
}: ConfirmationModalProps) => {
  // const { showToast } = useToast()

  const { isPrepareError, isSignatureLoading, transactionData, write } =
    useConfirm({
      address: recipientAddress,
      amount,
    })

  // const { data: gasData, isSuccess: isGasRetrievalSuccess } = useGasInfo({
  //   onError: () => {
  //     showToast({
  //       type: 'danger',
  //       header: 'Failed to connect to gas estimator',
  //       message:
  //         'Please refresh the page and  try again. If the problem persists, contact support.',
  //     })
  //   },
  // })

  const handleSubmit = () => {
    if (write) {
      write()
      // const currentMaxGasFee = Math.ceil(gasData.fast.maxFee)
      // const currentMaxPriorityFeePerGas = Math.ceil(gasData.fast.maxPriorityFee)
      // // Manually override gas configurations based on gas station results
      // write({
      //   recklesslySetUnpreparedOverrides: {
      //     maxFeePerGas: parseUnits(currentMaxGasFee.toString(), 'gwei'),
      //     maxPriorityFeePerGas: parseUnits(
      //       currentMaxPriorityFeePerGas.toString(),
      //       'gwei',
      //     ),
      //   },
      // })
    }
  }

  return transactionData ? (
    <Result
      txHash={transactionData.hash}
      recipientName={recipientName}
      amount={amount}
    />
  ) : (
    <VStack
      flex={1}
      maxWidth={'512px'}
      alignItems="stretch"
      flexShrink="0"
      width={'100%'}
      justifyContent="space-between"
    >
      <Flex
        position={'relative'}
        alignItems={'stretch'}
        flexDirection={'column'}
        flexGrow={1}
        maxHeight={{ base: '440px', md: '480px' }}
        filter={
          'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.06)) drop-shadow(0px 20px 6px rgba(0, 0, 0, 0.1))'
        }
      >
        <StepHeader step={2} title="Confirm payment" />
        <ConfirmationModal
          senderName={senderName}
          recipientName={recipientName}
          recipientAddress={recipientAddress}
          amount={amount}
        />
      </Flex>
      <Button
        padding="16px"
        loadingText="Signing Transaction"
        onClick={handleSubmit}
        isLoading={isSignatureLoading}
        isDisabled={isPrepareError}
      >
        Confirm
      </Button>
    </VStack>
  )
}

export default ConfirmationModalV2
