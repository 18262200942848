import { Box } from '@chakra-ui/react'

import { MERCHANT_URL_MAP } from 'constants/feedback'

const MerchantDirectory = () => {
  return (
    <Box w="100vw" height="calc(100vh - 30px);">
      <iframe src={MERCHANT_URL_MAP} width="100%" height="100%"></iframe>
    </Box>
  )
}

export default MerchantDirectory
