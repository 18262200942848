import { anatomy } from '@chakra-ui/theme-tools'

import { textStyles } from '../textStyles'
import { ComponentMultiStyleConfigWithParts } from '../types'

const parts = anatomy('Toast').parts(
  'container',
  'icon',
  'content',
  'header',
  'message',
  'close',
)

// TODO: Fix type, use same overrides as OGP Design System
export const Toast: ComponentMultiStyleConfigWithParts<typeof parts> = {
  parts: parts.keys,
  baseStyle: {
    container: {
      padding: '16px',
      borderRadius: '4px',
      borderWidth: '1px',
      alignItems: 'flex-start',
    },
    icon: {
      boxSize: '20px',
    },
    content: {
      marginLeft: '8px',
      spacing: '4px',
      alignItems: 'stretch',
    },
    header: {
      ...textStyles['subhead1'],
    },
    message: {
      ...textStyles['body1'],
    },
    close: {
      color: 'neutral.800',
    },
  },
  variants: {
    success: {
      container: {
        background: 'success.100',
        borderColor: 'success.500',
      },
    },
    info: {
      container: {
        background: 'primary.100',
        borderColor: 'primary.500',
      },
    },
    warning: {
      container: {
        background: 'warning.100',
        borderColor: 'warning.500',
      },
    },
    danger: {
      container: {
        background: 'danger.100',
        borderColor: 'danger.500',
      },
    },
  },
}
