import alerts from './alertsEN.json'
import common from './commonEN.json'
import errors from './errorsEN.json'
import header from './headerEN.json'
import help from './helpEN.json'
import main from './mainEN.json'
import redemption from './redemptionEN.json'
import subheader from './subheaderEN.json'
import toasts from './toastsEN.json'

export const translationsEN = {
  header,
  alerts,
  common,
  errors,
  help,
  main,
  redemption,
  toasts,
  subheader,
}
