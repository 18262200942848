import { useErrorStates } from 'hooks'
import { is404Error } from 'utils/helpers'

import ErrorTemplate from './ErrorTemplate'

// TODO(ts): Rethink error type here
interface VoucherErrorProps {
  error: unknown
}

const VoucherError = ({ error }: VoucherErrorProps) => {
  const errorStates = useErrorStates()

  return is404Error(error) ? (
    <ErrorTemplate errorObj={errorStates.NotFoundError} />
  ) : (
    <ErrorTemplate errorObj={errorStates.ServiceUnavailable} />
  )
}

export default VoucherError
