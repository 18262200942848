import { ReactNode } from 'react'
import { Text, VStack } from '@chakra-ui/react'

interface DebugTemplateProps {
  title: string
  children: ReactNode
}

const DebugTemplate = ({ title, children }: DebugTemplateProps) => {
  return (
    <VStack spacing="16px" alignItems="stretch">
      <Text textStyle="h4" color="primary.700">
        {title}
      </Text>
      {children}
    </VStack>
  )
}

export default DebugTemplate
