import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import qs from 'qs'

import { DEFAULT_LOCALE, QUERY_STRING_LOCALE_KEY } from 'constants/translation'
import { changeLanguage } from 'utils/i18n'

/**
 * Detects browser locale and sets language accordingly. Must be put within Router component
 */
const DetectDefaultLanguage = () => {
  const history = useHistory()
  const { location } = history
  // If language does not exist in query string, then insert the browser's language
  // into the query string. If browser language is not avaiable, then the default (en)
  // is used.
  useEffect(() => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (!query[QUERY_STRING_LOCALE_KEY]) {
      changeLanguage(navigator?.language || DEFAULT_LOCALE.code, history)
    }
  }, [history, location.search])
  return <></>
}

export default DetectDefaultLanguage
