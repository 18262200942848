import React from 'react'
import { useTranslation } from 'react-i18next'
import { BiTimeFive } from 'react-icons/bi'
import { FiLink } from 'react-icons/fi'
import {
  Button,
  HStack,
  Icon,
  Image,
  StackItem,
  Tag,
  Text,
  VStack,
} from '@chakra-ui/react'

import redeemLogo from 'assets/redeem-logo-light.svg'
import { formatDateString } from 'utils/date'
import LanguageSelector from 'components/LanguageSelector'
import LowGasInlineMessage from 'features/wallet/components/GroupPageHeader/components/LowGasInlineMessage'

interface GroupPageHeaderProps {
  onClickCopy: () => void
  campaignOrganiserFeedbackUrl: string | null
  address?: string
  logoSrc?: string | null
  campaignName?: string | null
  isInteractionDisabled?: boolean
  campaignExpiresAt?: string | null
  tokenSymbol?: string
  isInsufficientGas?: boolean
}

const GroupPageHeader = ({
  logoSrc,
  address,
  onClickCopy,
  isInteractionDisabled,
  campaignName,
  campaignExpiresAt,
  tokenSymbol,
  isInsufficientGas = false,
}: GroupPageHeaderProps) => {
  const { t } = useTranslation('header')
  return (
    <VStack
      alignItems={'stretch'}
      width="100%"
      flexShrink={0}
      maxWidth={'512px'}
      color={'white'}
      paddingX={{ base: '20px', sm: '24px', md: '40px' }}
      paddingY={'24px'}
      spacing={'20px'}
    >
      <HStack justify="space-between" marginTop={'12px'}>
        <Image
          maxWidth="138px"
          maxHeight="48px"
          alt="Campaign Logo"
          fallbackSrc={redeemLogo}
          src={logoSrc || redeemLogo}
        />
        <LanguageSelector />
      </HStack>

      <StackItem>
        <VStack align="start" marginTop="12px" spacing="4px">
          {!!tokenSymbol && (
            <Tag variant="outline" colorScheme="whiteAlpha">
              {tokenSymbol}
            </Tag>
          )}
          <Text textStyle="h3">{campaignName}</Text>

          {!!campaignExpiresAt && (
            <HStack spacing="8px">
              <Icon as={BiTimeFive} boxSize="16px" />
              <Text textStyle="body2">
                {t('use_by', {
                  expiryDate: formatDateString(campaignExpiresAt),
                })}
              </Text>
            </HStack>
          )}
        </VStack>
      </StackItem>

      <HStack
        justify="space-between"
        padding={'16px'}
        backgroundColor="primary.900"
      >
        <Text textStyle="caption2">{address}</Text>
        <Button
          flexShrink={0}
          maxWidth="9rem"
          whiteSpace="pre-wrap"
          backgroundColor="primary.600"
          colorScheme="primary"
          data-dd-action-name="Share voucher button"
          disabled={isInteractionDisabled}
          iconSpacing="0.25rem"
          leftIcon={<Icon as={FiLink} boxSize="16px" />}
          onClick={onClickCopy}
          size="sm"
        >
          {t('share')}
        </Button>
      </HStack>
      {isInsufficientGas && <LowGasInlineMessage />}
    </VStack>
  )
}

export default React.memo(GroupPageHeader)
