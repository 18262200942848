import alerts from './alertsTA.json'
import common from './commonTA.json'
import errors from './errorsTA.json'
import header from './headerTA.json'
import help from './helpTA.json'
import main from './mainTA.json'
import redemption from './redemptionTA.json'
import subheader from './subheaderTA.json'
import toasts from './toastsTA.json'

export const translationsTA = {
  alerts,
  header,
  common,
  errors,
  help,
  main,
  redemption,
  toasts,
  subheader,
}
