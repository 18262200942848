import { Flex, Spinner, VStack } from '@chakra-ui/react'
import { MerchantDto } from 'typings/voucher'

import { useGroupVoucher } from 'hooks'

import RecipientList from 'features/redemption/components/Offer/components/RecipientList'
import StepHeader from 'features/redemption/components/RedemptionCard/components/StepHeader'

interface OfferProps {
  campaignName: string
  amount: string
  onClose: () => void
  onAccept: (merchant: MerchantDto) => void
  campaignExpiresAt?: string | null
  campaignQrLogoUrl?: string | null
}

const Offer = ({ onAccept }: OfferProps) => {
  const { data, isLoading } = useGroupVoucher()

  return (
    <VStack
      flex={1}
      maxWidth={'512px'}
      alignItems="stretch"
      flexShrink="0"
      width={'100%'}
      justifyContent="space-between"
    >
      <Flex
        position={'relative'}
        alignItems={'stretch'}
        flexDirection={'column'}
        flexGrow={1}
        filter={
          'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.06)) drop-shadow(0px 20px 6px rgba(0, 0, 0, 0.1))'
        }
        boxSizing={'border-box'}
        maxH="70vh"
      >
        <StepHeader step={1} title="Select Merchant" />
        {isLoading || !data ? (
          <Spinner />
        ) : (
          <RecipientList
            onRecipientSelect={onAccept}
            recipients={data.merchants}
          />
        )}
      </Flex>
    </VStack>
  )
}

export default Offer
