import '@fontsource/inter/300.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'
import 'focus-visible/dist/focus-visible'

import { HelmetProvider } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
// Theme
import { ChakraProvider } from '@chakra-ui/react'
import { css, Global } from '@emotion/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { WagmiConfig } from 'wagmi'

// Translations
import { theme } from 'theme'
// Route paths
import { NO_MATCH_ROUTE, ROOT_ROUTE } from 'constants/routes'
import { DEFAULT_LOCALE } from 'constants/translation'
import { initTranslations } from 'utils/i18n'
import { initTimezone } from 'utils/timezone'
// Header
import GovernmentBanner from 'components/basic/GovernmentBanner'
import DetectDefaultLanguage from 'components/DetectDefaultLanguage'
import { initMonitoring } from 'lib/datadog'
import { web3Client } from 'lib/wagmi'

import MerchantDirectory from 'features/map/MerchantDirectory'
// Pages
import GroupPage from 'features/wallet'

const GlobalStyles = css`
  /*
    This will hide the focus indicator if the element receives focus via the mouse,
    but it will still show up on keyboard focus.
  */
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
`

const helmetContext = {}
const queryClient = new QueryClient()

initTimezone()
initTranslations()
initMonitoring()

export const App = () => {
  const { i18n: i18nInstance } = useTranslation()
  const language =
    i18nInstance?.language?.substring(0, 2) || DEFAULT_LOCALE.code // Get base language locale
  return (
    <HelmetProvider context={helmetContext}>
      <QueryClientProvider client={queryClient}>
        <WagmiConfig client={web3Client}>
          <ChakraProvider theme={theme}>
            <Global styles={GlobalStyles} />
            <div
              id="language-wrapper"
              className={`language-wrapper language-${language}`}
            >
              <GovernmentBanner />
              <Router>
                <DetectDefaultLanguage />
                <Switch>
                  <Route
                    exact
                    path={NO_MATCH_ROUTE}
                    render={() => {
                      window.location.href = 'https://redeem.gov.sg'
                      return null
                    }}
                  />

                  <Route path={`/merchants`}>
                    <MerchantDirectory />
                  </Route>
                  <Route path={`${ROOT_ROUTE}:groupId`}>
                    <GroupPage />
                  </Route>

                  <Redirect from="*" to={NO_MATCH_ROUTE} />
                </Switch>
              </Router>
            </div>
          </ChakraProvider>
        </WagmiConfig>
      </QueryClientProvider>
    </HelmetProvider>
  )
}
