import { VStack } from '@chakra-ui/react'
import { useAccount } from 'wagmi'

import { DSGD_ADDRESS, PBM_ADDRESS } from 'constants/config'

import ErrorPrompt from 'features/debugger/components/Login/ErrorPrompt'
import TokenListing from 'features/debugger/components/TokenListing'
import TransactionListing from 'features/debugger/components/Transaction/TransactionListing'

const pbmToken = PBM_ADDRESS
const dsgdToken = DSGD_ADDRESS

const DebugPanel = () => {
  const { address, isConnected } = useAccount()

  if (!isConnected || !address) {
    return <ErrorPrompt />
  }

  return (
    <VStack alignItems="stretch" w="100%" spacing="20px">
      <TokenListing tokens={[pbmToken, dsgdToken]} clientAddress={address} />
      <TransactionListing tokens={[pbmToken]} clientAddress={address} />
    </VStack>
  )
}

export default DebugPanel
