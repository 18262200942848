import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import _ from 'lodash'
import { GroupedVoucherResponse } from 'typings/voucher'

import { is404Error } from 'utils/helpers'
import { fetchGroupById } from 'services/RedeemApi'

const RETRIES_MAX = 3

export const useGroupVoucher = () => {
  const { groupId } = useParams<{ groupId: string }>()

  return useQuery(['group', groupId], () => fetchGroupById(groupId), {
    select: useCallback(
      (data: GroupedVoucherResponse) => ({
        accessKey: data.data.accessKey,
        merchants: data.merchants,
        mappedMerchants: _.keyBy(data.merchants, 'walletAddress'),
      }),
      [],
    ),

    retry: (failureCount, responseError) => {
      return !is404Error(responseError) && failureCount < RETRIES_MAX
    },
  })
}
