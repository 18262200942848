// Libraries
import { useTranslation } from 'react-i18next'
import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from '@chakra-ui/react'
import { useAccount, useBalance } from 'wagmi'

import { PBM_ADDRESS } from 'constants/config'
import {
  FEEDBACK_EMAIL,
  TOU_URL,
  WHATSAPP_CONTACT_LINK,
} from 'constants/feedback'
import {
  useAutoConnect,
  useCampaignInfo,
  useGroupVoucher,
  useToast,
} from 'hooks'
import { formatBigNumberToDisplay } from 'utils/web3'
// Hooks
// General Components
import VoucherError from 'components/VoucherError'
import { setDatadogUser } from 'lib/datadog'

import DebugPanel from 'features/debugger/DebugPanel'
import Payment from 'features/redemption/Payment'
import { useCopyUrlToClipboard, useMaticBalance } from 'features/wallet/hooks'

import GroupPageInactiveCampaign from './components/GroupPageErrors/GroupPageInactiveCampaign'
import GroupPageHeader from './components/GroupPageHeader'
import GroupPageLoadingScreen from './components/GroupPageLoadingScreen'
import GroupPageSubHeader from './components/GroupPageSubHeader'
import HelpDrawer from './components/HelpDrawer'

// Constants

const GroupPage = () => {
  const { t } = useTranslation('toasts')
  const { showToast } = useToast()

  const {
    onClose: closeHelpDrawer,
    onOpen: openHelpDrawer,
    isOpen: isHelpDrawerOpen,
  } = useDisclosure({ defaultIsOpen: false })

  const {
    data: groupData,
    status: groupFetchStatus,
    error: groupError,
  } = useGroupVoucher()

  useAutoConnect({ accessKey: groupData?.accessKey })

  const { address } = useAccount({
    onConnect({ address, connector, isReconnected }) {
      if (address) {
        setDatadogUser({ name: address })
      }
    },
  })

  const { isLowGas } = useMaticBalance({ clientAddress: address })

  const { data } = useBalance({
    token: PBM_ADDRESS,
    addressOrName: address,
    chainId: 5,
    watch: true,
    onError: (e) => {
      showToast({
        type: 'danger',
        header: 'Failed to connect to provider',
        message: `reason: ${e.name}`,
      })
    },
  })

  const onClickCopy = useCopyUrlToClipboard({
    toast: {
      header: t('copy_success.header'),
      message: t('copy_success.message'),
    },
  })

  const { isLoading: isCampaignLoading, data: campaignData } = useCampaignInfo({
    tokenAddress: PBM_ADDRESS,
  })

  const isCampaignInactive = campaignData?.isPaused

  if (groupFetchStatus === 'error') {
    return <VoucherError error={groupError} />
  }

  if (groupFetchStatus === 'loading' || isCampaignLoading) {
    return <GroupPageLoadingScreen />
  }

  return (
    <>
      {groupFetchStatus === 'success' && (
        <Flex
          alignItems="center"
          flexDirection="column"
          flexGrow={1}
          backgroundColor="primary.800"
          id="voucher-group-page"
        >
          <GroupPageHeader
            logoSrc={null}
            onClickCopy={onClickCopy}
            isInteractionDisabled={isCampaignInactive}
            campaignName={campaignData?.name ? 'PBM Demo' : undefined}
            campaignExpiresAt={campaignData?.contractExpiry.toString()}
            campaignOrganiserFeedbackUrl={'placeholder'}
            tokenSymbol={data?.symbol}
            isInsufficientGas={isLowGas}
          />
          <GroupPageSubHeader
            amountLeft={data ? formatBigNumberToDisplay(data.value) : '-'}
            onClickHelp={openHelpDrawer}
          />
          <Flex
            alignItems={'flex-start'}
            justifyContent={'center'}
            flex={1}
            width={'100%'}
            marginTop={'-16px'}
            background={'primary.100'}
            borderTopRadius={'16px'}
            boxShadow={'box-shadow: 0px 0px 20px rgba(97, 108, 137, 0.3)'}
          >
            <Flex
              alignItems={'stretch'}
              flexWrap={'wrap'}
              width={'100%'}
              maxWidth={'512px'}
              height={'inherit'}
              marginTop={'12px'}
              paddingTop={'4px'}
              paddingBottom={'calc(36px + 4rem)'}
              paddingX={{ base: '20px', sm: '24px', md: '40px' }}
            >
              {/* Render inactive campaign or grouped vouchers accordingly
            to campaign state */}
              {isCampaignInactive ? (
                <GroupPageInactiveCampaign
                  campaignOrganiserContactNumber={'placeholder'}
                />
              ) : (
                <Tabs isFitted variant="enclosed" width="100%" isLazy>
                  <TabList>
                    <Tab>Wallet</Tab>
                    <Tab>Details</Tab>
                  </TabList>
                  <TabPanels marginTop="16px">
                    <TabPanel>
                      <Payment
                        maxAmountSpendable={Number(data?.formatted) || 0}
                      />
                    </TabPanel>
                    <TabPanel>
                      <DebugPanel />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              )}
            </Flex>
          </Flex>
        </Flex>
      )}
      <HelpDrawer
        isOpen={isHelpDrawerOpen}
        onCloseClick={closeHelpDrawer}
        campaignOrganiserName={campaignData?.name}
        campaignOrganiserContactLink={WHATSAPP_CONTACT_LINK}
        campaignMerchantListUrl={'/merchants'}
        campaignTouUrl={TOU_URL}
        campaignOrganiserEmail={FEEDBACK_EMAIL}
      />
    </>
  )
}

export default GroupPage
