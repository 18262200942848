import { useProvider, useQuery } from 'wagmi'
import { fetchListOfRedemptions } from 'services/RedeemApi'
import _ from 'lodash'
import moment from 'moment-timezone'
import { BigNumber } from 'ethers'
import { useCallback } from 'react'
import { RedemptionEventWithTimestamp } from 'typings/transaction'

interface UseListRedemptionsParams {
  fromAddress?: string
}

export const useListRedemptions = ({
  fromAddress = '',
}: UseListRedemptionsParams) => {
  const provider = useProvider()

  return useQuery(
    ['redemption', fromAddress],
    async (): Promise<RedemptionEventWithTimestamp[]> => {
      const redemptionEvents = await fetchListOfRedemptions(fromAddress)

      const mappedValues = _.map(redemptionEvents.data, async (evt) => {
        return {
          ...evt,
          redemptionValue: BigNumber.from(evt.args[2]),
          recipientAddress: evt.args[1],
          timestamp: await provider.getBlock(evt.blockNumber).then((block) => {
            return moment.unix(block.timestamp).format()
          }),
        }
      })

      return (await Promise.all(mappedValues)) ?? []
    },
    {
      refetchInterval: 0,
      enabled: !!fromAddress,
      select: useCallback((events: RedemptionEventWithTimestamp[]) => {
        return _.orderBy(events, 'blockNumber', 'desc')
      }, []),
    },
  )
}
