import { configureChains, createClient } from 'wagmi'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { infuraProvider } from 'wagmi/providers/infura'
import { publicProvider } from 'wagmi/providers/public'
import { alchemyProvider } from 'wagmi/providers/alchemy'

import { ALCHEMY_API_KEY, CHAIN, INFURA_API_KEY } from 'constants/config'
import { getAnkrRpcUrl } from 'utils/web3'
import { LocalWallet } from 'lib/connectors/local'

const fallbackInfuraProvider = INFURA_API_KEY
  ? infuraProvider({ apiKey: INFURA_API_KEY })
  : infuraProvider()

const fallbackAlchemyProvider = ALCHEMY_API_KEY
  ? alchemyProvider({ apiKey: ALCHEMY_API_KEY })
  : publicProvider()

// Configuration for read-only provider functions
const { chains, provider } = configureChains(
  [CHAIN],
  [
    jsonRpcProvider({
      // NOTE: Ankr providers have an unconventional naming scheme for network name
      rpc: (chain) => ({
        http: getAnkrRpcUrl(chain),
      }),
    }),
    fallbackInfuraProvider,
    fallbackAlchemyProvider,
  ],
)

export const localConnector = new LocalWallet({
  chains,
  options: {},
})

export const web3Client = createClient({
  autoConnect: true,
  connectors: [localConnector],
  provider,
})
