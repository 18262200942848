import { useCallback } from 'react'

import { useToast } from 'hooks'
import { copyUrlToClipboard } from 'utils/helpers'

// TODO: Make hook more specific. No reason to have it allow for dynamic header/message
// given how specific it's utility is
export const useCopyUrlToClipboard = ({
  toast: { header, message },
}: {
  toast: { header: string; message: string }
}) => {
  const { showToast } = useToast()

  return useCallback(() => {
    copyUrlToClipboard()
    showToast({ type: 'success', header, message })
  }, [header, message])
}
