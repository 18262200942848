import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react'

interface RedemptionDrawerContextProps {
  isDisableReturn: boolean
  setDisableReturn: (isDisabled: boolean) => void
}

const RedemptionDrawerContext = createContext<
  RedemptionDrawerContextProps | undefined
>(undefined)

export const RedemptionDrawerProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [isDisableReturn, setIsDisableReturn] = useState(false)

  const setDisableReturn = useCallback((isDisable: boolean) => {
    setIsDisableReturn(isDisable)
  }, [])

  const value = {
    isDisableReturn,
    setDisableReturn,
  }

  return (
    <RedemptionDrawerContext.Provider value={value}>
      {children}
    </RedemptionDrawerContext.Provider>
  )
}

export const useRedemptionDrawerContext = () => {
  const context = useContext(RedemptionDrawerContext)
  if (!context) {
    throw new Error(
      'Redemption context must be used within a RedemptionDrawerProvider',
    )
  }
  return context
}
