import { providers, Wallet } from 'ethers'
import { getAddress } from 'ethers/lib/utils'
import { Chain, Connector, ConnectorData } from 'wagmi'

import { CHAIN } from 'constants/config'
import { getAnkrRpcUrl } from 'utils/web3'

function normalizeChainId(chainId: string | number) {
  if (typeof chainId === 'string') {
    const isHex = chainId.trim().substring(0, 2)

    return Number.parseInt(chainId, isHex === '0x' ? 16 : 10)
  }
  return chainId
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LocalWalletOptions {}

export class LocalWallet extends Connector<
  providers.FallbackProvider,
  LocalWalletOptions
> {
  readonly id = 'rsgWallet'
  readonly name = 'Redeem Wallet'

  // TODO: reconsider dynamic ready state
  readonly ready = true

  provider?: providers.FallbackProvider
  accessKey: string
  localWallet: Wallet

  constructor(config: { chains?: Chain[]; options: LocalWalletOptions }) {
    super(config)
  }

  async getProvider(): Promise<providers.FallbackProvider> {
    console.log('triggered get provider')

    // Sets up provider to use a dedicated provider (if available)
    // Defaults to a public alchemy provider if dedicated provider fails
    if (!this.provider) {
      this.provider = new providers.FallbackProvider([
        {
          provider: new providers.JsonRpcProvider(getAnkrRpcUrl(CHAIN)),
          priority: 1,
        },
        {
          provider: new providers.InfuraProvider(CHAIN.id),
          priority: 2,
        },
      ])
    }

    // TODO: Override baseclass
    // Instantiation is not an async call. Base class is not designed for this hacky implementation
    return Promise.resolve(this.provider)
  }

  async disconnect() {
    if (!this.provider) return

    const provider = await this.getProvider()
    provider.removeListener('accountsChanged', this.onAccountsChanged)
    provider.removeListener('chainChanged', this.onChainChanged)
    provider.removeListener('disconnect', this.onDisconnect)

    // TODO: figure out an elegant way to detach wallet from provider
  }

  async getAccount(): Promise<string> {
    console.log('getAccount triggered')

    return Promise.resolve(this.localWallet.address)
  }

  async getNetwork(): Promise<providers.Network> {
    const provider = await this.getProvider()
    return provider.getNetwork()
  }

  setAccessKey(accessKey: string) {
    this.accessKey = accessKey
  }

  async getChainId(): Promise<number> {
    const network = this.getNetwork()
    return network.then((res) => res.chainId)
  }

  getSigner(_config: { chainId?: number } | undefined) {
    console.log('getSigner triggered')

    return Promise.resolve(this.localWallet)
  }

  isAuthorized(): Promise<boolean> {
    return Promise.resolve(false)
  }

  protected onAccountsChanged(accounts: string[]) {
    if (accounts.length === 0) this.emit('disconnect')
    else this.emit('change', { account: getAddress(accounts[0]) })
  }

  protected onChainChanged(chainId: string | number) {
    const id = normalizeChainId(chainId)
    const unsupported = this.isChainUnsupported(id)
    this.emit('change', { chain: { id, unsupported } })
  }

  protected onDisconnect() {
    this.emit('disconnect')
  }

  async connect(
    config: { chainId?: number } = {},
  ): Promise<Required<ConnectorData>> {
    if (!this.accessKey) {
      throw new Error('Failed to retrieve access key')
    }

    try {
      // Retrieves the provider, to connect to wallet instance
      const provider = await this.getProvider()

      // Retrieves access key from keystore
      this.localWallet = new Wallet(this.accessKey, provider)

      // Setup provider listeners
      provider.on('accountsChanged', this.onAccountsChanged)
      provider.on('chainChanged', this.onChainChanged)
      provider.on('disconnect', this.onDisconnect)

      this.emit('message', { type: 'connecting' })

      const providerNetworkConfig = await provider?.getNetwork()
      const walletChainId = providerNetworkConfig.chainId

      return {
        account: this.localWallet.address,
        chain: {
          id: walletChainId,
          unsupported: config?.chainId !== walletChainId,
        },
        provider: this.localWallet.provider,
      }
    } catch (e) {
      throw Error('unable to connect to account')
    }
  }
}
