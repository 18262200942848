import { ContactLink } from 'typings/config'

export const MERCHANT_URL_MAP =
  'https://www.google.com/maps/d/embed?mid=1f4ASNt1_4XM5bMC2VGO-wVL1m7hknIc&ehbc=2E312F'

export const TOU_URL =
  'https://documents.dsgd.gov.sg/Terms+and+Condition+for+PBM+Recipients.pdf'

export const WHATSAPP_CONTACT_LINK: ContactLink = {
  number: '6584697712',
  link: 'https://wa.me/message/SKBW24TSWKGFP1',
}
export const FEEDBACK_EMAIL = 'cdbc@open.gov.sg'
