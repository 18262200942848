import React from 'react'
import { useTranslation } from 'react-i18next'
import { BiInfoCircle } from 'react-icons/bi'
import { Box, Button, HStack, Icon, Text } from '@chakra-ui/react'

type GroupPageSubHeaderProps = {
  amountLeft?: string
  onClickHelp: () => void
}

const GroupPageSubHeader = ({
  amountLeft,
  onClickHelp,
}: GroupPageSubHeaderProps) => {
  const { t } = useTranslation('subheader')
  return (
    <Box
      width="100%"
      paddingTop="10px"
      borderTopRadius="16px"
      backgroundColor="primary.500"
      paddingBottom="26px"
    >
      <HStack
        justify="space-between"
        maxWidth="512px"
        marginX="auto"
        paddingX={{
          xs: '20px',
          sm: '24px',
          md: '40px',
        }}
      >
        <HStack color="white" spacing="8px">
          <Text textStyle="subhead2">{t('balance')}</Text>
          <HStack spacing={0}>
            <Text textStyle="caption1">$</Text>
            <Text textStyle="h3">{amountLeft || '-'}</Text>
          </HStack>
        </HStack>
        <Button
          textStyle="subhead2"
          padding="8px 12px"
          background="primary.600"
          data-dd-action-name="Open help drawer button"
          iconSpacing="4px"
          id="open-help-drawer-button"
          leftIcon={<Icon as={BiInfoCircle} boxSize="16px" />}
          onClick={onClickHelp}
          size="sm"
        >
          <Text textStyle="subhead2">{t('help_drawer_button')}</Text>
        </Button>
      </HStack>
    </Box>
  )
}

export default React.memo(GroupPageSubHeader)
