import { chain, etherscanBlockExplorers } from 'wagmi'

const appEnv = process.env.REACT_APP_ENV
// TODO: Refactor to define URLs in env vars rather than hardcoding them

// Define the api URLs here
const productionBaseUrl = 'https://api-experimental.dsgd.gov.sg'
const stagingBaseUrl = 'https://api-staging.dsgd.gov.sg'
const localBaseUrl = 'http://localhost:10000'
const vaptBaseUrl = 'https://api-vapt.redeem.gov.sg'

// Define the campaign listing URLs here
const productionCampaignListingUrl = 'https://signup.redeem.gov.sg/'
const stagingCampaignListingUrl = 'https://signup-cdc-staging.redeem.gov.sg/'
const localCampaignListingUrl = 'http://localhost:3001/'
const vaptCampaignListingUrl = 'https://signup-vapt.redeem.gov.sg/'

export const voucherBaseUrl = (() => {
  if (appEnv === 'production') {
    return productionBaseUrl
  }
  if (appEnv === 'staging') {
    return stagingBaseUrl
  }
  if (appEnv === 'vapt') {
    return vaptBaseUrl
  }
  return localBaseUrl
})()

export const campaignListingUrl = (() => {
  if (appEnv === 'production') {
    return productionCampaignListingUrl
  }
  if (appEnv === 'staging') {
    return stagingCampaignListingUrl
  }
  if (appEnv === 'vapt') {
    return vaptCampaignListingUrl
  }
  return localCampaignListingUrl
})()

export const gasStationBaseUrl =
  appEnv === 'production'
    ? 'https://gasstation-mainnet.matic.network'
    : 'https://gasstation-mumbai.matic.today'

// WEB3 Related Configurations

export const PBM_DECIMALS = 18

export const BLOCK_EXPLORER =
  appEnv === 'production'
    ? etherscanBlockExplorers.polygonMumbai.url
    : etherscanBlockExplorers.polygonMumbai.url

export const CHAIN =
  appEnv === 'production' ? chain.polygonMumbai : chain.polygonMumbai

// Mumbai Testnet mines at 1 block / 5s
// Main-net mines at 1 block / 2s
export const BLOCK_CONFIRMATIONS = appEnv === 'production' ? 1 : 1

// Config variables for contract addresses
export const DSGD_ADDRESS = process.env.REACT_APP_DSGD_ADDRESS || ''
export const PBM_ADDRESS = process.env.REACT_APP_PBM_ADDRESS || ''

export const INFURA_API_KEY = process.env.REACT_APP_INFURA_API_KEY || ''
export const ALCHEMY_API_KEY = process.env.REACT_APP_ALCHEMY_API_KEY || ''

export const DATADOG_SAMPLE_RATE =
  Number(process.env.REACT_APP_DATADOG_SAMPLE_RATE) || 100

export const LOW_GAS_TRESHOLD = process.env.REACT_APP_LOW_GAS_TRESHOLD || '0.02'
