import { Spinner, Text, VStack } from '@chakra-ui/react'

const Loader = () => {
  return (
    <VStack
      height="80vh"
      alignItems="center"
      justifyContent="center"
      color="primary.700"
      spacing="32px"
    >
      <Text textStyle="h3" textTransform="uppercase">
        Loading
      </Text>
      <Spinner size="xl" />
    </VStack>
  )
}

export default Loader
