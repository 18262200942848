import { useTranslation } from 'react-i18next'
import { Text, VStack } from '@chakra-ui/react'

import merchantListBtnBackground from 'assets/merchant-list-btn-background.svg'
import voucherInfoBtnBackground from 'assets/voucher-info-btn-background.svg'

import HelpLearnMoreButton from './components/HelpLearnMoreButton'

type MoreInfoSectionProps = {
  campaignTouUrl?: string | null
  campaignMerchantListUrl?: string | null
}

const MoreInfoSection = ({
  campaignTouUrl,
  campaignMerchantListUrl,
}: MoreInfoSectionProps) => {
  const { t } = useTranslation('help', {
    keyPrefix: 'more_info',
  })
  return (
    <VStack
      align="stretch"
      paddingTop="12px"
      paddingBottom="36px"
      paddingX="16px"
      spacing="24px"
    >
      <Text textStyle="h4" color="primary.700" paddingX="8px">
        {t('title')}
      </Text>
      <VStack align="stretch" spacing="16px">
        {!!campaignMerchantListUrl && (
          <HelpLearnMoreButton
            image={merchantListBtnBackground}
            imagePosition="right 0px bottom -1px"
            title={t('merchant_list_button.title')}
            description={t('merchant_list_button.desc')}
            href={campaignMerchantListUrl}
          />
        )}
        {!!campaignTouUrl && (
          <HelpLearnMoreButton
            image={voucherInfoBtnBackground}
            imagePosition="right 12px bottom 0px"
            title="Terms and Conditions"
            description={'Click here for more information'}
            href={campaignTouUrl}
          />
        )}
      </VStack>
    </VStack>
  )
}

export default MoreInfoSection
