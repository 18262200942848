import { Divider, HStack, StackItem, Text, VStack } from '@chakra-ui/react'

interface ConfirmationModalProps {
  senderName: string
  recipientName: string
  recipientAddress: string
  amount: string
}

// TODO: Add shadows and border radiuses
const ConfirmationModal = ({
  senderName,
  recipientName,
  recipientAddress,
  amount,
}: ConfirmationModalProps) => {
  return (
    <VStack
      width="100%"
      alignItems="flex-start"
      background="white"
      borderBottomRadius="4px"
    >
      <HStack padding="24px" justifyContent="space-between" width="100%">
        <VStack spacing="4px" alignItems="flex-start">
          <Text textStyle="caption2" color="neutral.700">
            From
          </Text>
          <Text textStyle="subhead1" color="primary.600">
            {senderName}
          </Text>
        </VStack>

        <HStack color="primary.600">
          <Text textStyle="subhead1">$</Text>
          <Text textStyle="h2">{amount}</Text>
        </HStack>
      </HStack>
      <Divider />
      <HStack padding="24px" justifyContent="space-between" width="100%">
        <VStack spacing="4px" alignItems="flex-start">
          <Text textStyle="caption2" color="neutral.700">
            To
          </Text>
          <StackItem>
            <Text textStyle="subhead1" color="primary.600">
              {recipientName}
            </Text>
            <Text
              textStyle="caption2"
              color="primary.400"
              noOfLines={1}
              maxW="300px"
            >
              {recipientAddress}
            </Text>
          </StackItem>
        </VStack>
      </HStack>
    </VStack>
  )
}

export default ConfirmationModal
