import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  VStack,
} from '@chakra-ui/react'
import { ContactLink } from 'typings/config'

import MoreInfoSection from './components/MoreInfoSection'
import OrganiserContactSection from './components/OrganiserContactSection'

type HelpDrawerProps = {
  isOpen: boolean
  onCloseClick: () => void
  campaignTouUrl?: string | null
  campaignOrganiserName?: string | null
  campaignMerchantListUrl?: string | null
  campaignOrganiserFeedbackUrl?: string | null
  campaignOrganiserLocation?: string | null
  campaignOrganiserContactLink?: ContactLink | null
  campaignOrganiserEmail?: string | null
}

function HelpDrawer({
  onCloseClick,
  isOpen,
  campaignTouUrl,
  campaignMerchantListUrl,
  campaignOrganiserName,
  campaignOrganiserFeedbackUrl,
  campaignOrganiserLocation,
  campaignOrganiserContactLink,
  campaignOrganiserEmail,
}: HelpDrawerProps) {
  const { i18n } = useTranslation(['help', 'common'])
  return (
    // TODO: Consider using useMultiStyleConfig
    <Drawer isOpen={isOpen} onClose={onCloseClick} placement="bottom">
      <DrawerOverlay />
      <DrawerContent overflow="auto" height="90%" background="primary.100">
        <DrawerBody
          position="relative"
          maxWidth="512px"
          width="100%"
          margin="0 auto"
          paddingBottom="0"
        >
          {(!!campaignTouUrl || !!campaignMerchantListUrl) && (
            <MoreInfoSection
              campaignTouUrl={campaignTouUrl}
              campaignMerchantListUrl={campaignMerchantListUrl}
            />
          )}
          <Divider borderColor="primary.200" />
          <VStack
            align="stretch"
            background="white"
            paddingX="24px"
            paddingY="36px"
            spacing={0}
          >
            <VStack align="stretch" spacing="56px">
              <OrganiserContactSection
                campaignOrganiserName={campaignOrganiserName}
                campaignOrganiserLocation={campaignOrganiserLocation}
                campaignOrganiserContactLink={campaignOrganiserContactLink}
                campaignOrganiserFeedbackUrl={campaignOrganiserFeedbackUrl}
                campaignOrganiserEmail={campaignOrganiserEmail}
              />
            </VStack>
          </VStack>
        </DrawerBody>
        <DrawerFooter
          zIndex={1}
          width="100%"
          maxWidth="512px"
          margin="0 auto"
          background="white"
          boxShadow="0px 0px 20px rgba(97, 108, 137, 0.3)"
        >
          <Button
            textAlign="center"
            colorScheme="primary"
            width={'100%'}
            onClick={onCloseClick}
          >
            {/* Wrapper to properly center for older iOS browsers */}
            <Flex
              alignItems="center"
              justifyContent="center"
              width="100%"
              height="100%"
            >
              <Trans i18n={i18n} ns="common" i18nKey="buttons.close">
                Close
              </Trans>
            </Flex>
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

export default React.memo(HelpDrawer)
