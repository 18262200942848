import InlineMessage from 'components/InlineMessage'

const LowGasInlineMessage = () => {
  return (
    <InlineMessage isHidden={false}>
      Your account is currently low on gas. Please contact support should
      transactions fail.
    </InlineMessage>
  )
}

export default LowGasInlineMessage
