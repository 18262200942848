import alerts from './alertsZH.json'
import common from './commonZH.json'
import errors from './errorsZH.json'
import header from './headerZH.json'
import help from './helpZH.json'
import main from './mainZH.json'
import redemption from './redemptionZH.json'
import subheader from './subheaderZH.json'
import toasts from './toastsZH.json'

export const translationsZH = {
  header,
  alerts,
  common,
  errors,
  help,
  main,
  redemption,
  toasts,
  subheader,
}
