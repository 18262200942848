import {
  Avatar,
  HStack,
  LinkBox,
  StackItem,
  Text,
  VStack,
} from '@chakra-ui/react'
import { MerchantDto } from 'typings/voucher'

interface RecipientCardProps {
  name: string
  address: string
  onClick: (merchant: MerchantDto) => void
}

const RecipientCard = ({ name, address, onClick }: RecipientCardProps) => {
  return (
    <LinkBox
      w="100%"
      onClick={() => onClick({ shopName: name, walletAddress: address })}
    >
      <HStack
        spacing="10px"
        borderColor="primary.300"
        borderRadius="4px"
        borderWidth="thin"
        paddingX="12px"
        paddingY="15px"
        backgroundColor="primary.100"
      >
        <Avatar bg="primary.500" />
        <VStack alignItems="flex-start" spacing="8px">
          <StackItem>
            <Text textStyle="subhead1">{name}</Text>
            <Text
              textStyle="legal"
              noOfLines={1}
              maxW="200px"
              color="neutral.700"
            >
              {address}
            </Text>
          </StackItem>
        </VStack>
      </HStack>
    </LinkBox>
  )
}

export default RecipientCard
