import { VStack } from '@chakra-ui/react'
import { Merchant, MerchantDto } from 'typings/voucher'

import RecipientCard from './RecipientCard'

interface RecipientListProps {
  recipients: Merchant[]
  onRecipientSelect: (merchant: MerchantDto) => void
}

const RecipientList = ({
  recipients,
  onRecipientSelect,
}: RecipientListProps) => {
  return (
    <VStack
      overflowY="scroll"
      width="100%"
      alignItems="flex-start"
      background="white"
      borderBottomRadius="4px"
      paddingTop="20px"
      paddingBottom="40px"
      paddingX="10px"
    >
      {recipients.map(({ shopName, walletAddress }) => (
        <RecipientCard
          key={walletAddress}
          name={shopName}
          address={walletAddress}
          onClick={onRecipientSelect}
        />
      ))}
    </VStack>
  )
}

export default RecipientList
