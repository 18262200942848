import { HStack, LinkBox, LinkOverlay, Text, VStack } from '@chakra-ui/react'
import moment from 'moment-timezone'

import { BLOCK_EXPLORER } from 'constants/config'

const TRANSACTION_DATE_FORMAT = 'DD MMM YYYY h:mm A'

interface TransactionCardProps {
  transactionHash: string
  transactionValue: string
  timestamp: string
  name?: string
}

const TransactionCard = ({
  transactionHash,
  transactionValue,
  timestamp,
  name = 'Placeholder Merchant',
}: TransactionCardProps) => {
  return (
    <LinkBox>
      <HStack
        backgroundColor="neutral.100"
        paddingY="16px"
        paddingX="24px"
        alignItems="center"
        justifyContent="space-between"
      >
        <VStack alignItems="flex-start" spacing="1px">
          <Text textStyle="subhead2" color="neutral.800">
            {name}
          </Text>
          <LinkOverlay href={`${BLOCK_EXPLORER}/tx/${transactionHash}`}>
            <Text textStyle="body2" color="neutral.600">
              {moment(timestamp).format(TRANSACTION_DATE_FORMAT)}
            </Text>
          </LinkOverlay>
        </VStack>
        <HStack color="neutral.700" spacing="2px">
          <Text textStyle="subhead2">$</Text>
          <Text textStyle="h3">{transactionValue}</Text>
        </HStack>
      </HStack>
    </LinkBox>
  )
}

export default TransactionCard
