import { SystemStyleObjectRecord } from '@chakra-ui/react'

export const textStyles: SystemStyleObjectRecord = {
  display1: {
    fontSize: '4rem',
    lineHeight: '4.5rem',
    fontWeight: 700,
    letterSpacing: '-0.022em',
    fontFeatureSettings: `'cv05' on`,
  },
  display2: {
    fontSize: '2.5rem',
    lineHeight: '3rem',
    fontWeight: 600,
    letterSpacing: '-0.022em',
    fontFeatureSettings: `'cv05' on`,
  },
  h1: {
    fontSize: '3rem',
    lineHeight: '3.5rem',
    fontWeight: 300,
    letterSpacing: '-0.022em',
    fontFeatureSettings: `'tnum' on, 'lnum' on, 'cv05' on`,
  },
  h2: {
    fontSize: '1.75rem',
    lineHeight: '2.25rem',
    fontWeight: 600,
    letterSpacing: '-0.02em',
    fontFeatureSettings: `'tnum' on, 'lnum' on, 'cv05' on`,
  },
  h3: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
    fontWeight: 600,
    letterSpacing: '-0.019em',
    fontFeatureSettings: `'tnum' on, 'lnum' on, 'cv05' on`,
  },
  h4: {
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    fontWeight: 600,
    letterSpacing: '-0.019em',
    fontFeatureSettings: `'tnum' on, 'lnum' on, 'cv05' on`,
  },
  h5: {
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    fontWeight: 500,
    letterSpacing: '-0.014em',
    fontFeatureSettings: `'tnum' on, 'lnum' on, 'cv05' on`,
  },
  subhead1: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: 500,
    letterSpacing: '-0.011em',
    fontFeatureSettings: `'tnum' on, 'lnum' on, 'cv05' on`,
  },
  subhead2: {
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    fontWeight: 500,
    letterSpacing: '-0.006em',
    fontFeatureSettings: `'tnum' on, 'lnum' on, 'cv05' on`,
  },
  subhead3: {
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    fontWeight: 600,
    letterSpacing: '0.08em',
    textTransform: 'uppercase',
    fontFeatureSettings: `'tnum' on, 'lnum' on, 'cv05' on`,
  },
  body1: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: 400,
    letterSpacing: '-0.011em',
    fontFeatureSettings: `'tnum' on, 'lnum' on, 'cv05' on`,
  },
  body2: {
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    fontWeight: 400,
    letterSpacing: '-0.006em',
    fontFeatureSettings: `'tnum' on, 'lnum' on, 'cv05' on`,
  },
  caption1: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: 500,
    fontFeatureSettings: `'tnum' on, 'lnum' on, 'cv05' on`,
  },
  caption2: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: 400,
    fontFeatureSettings: `'tnum' on, 'lnum' on, 'cv05' on`,
  },
  link: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: 500,
    letterSpacing: '-0.011em',
    fontFeatureSettings: `'tnum' on, 'lnum' on, 'cv05' on`,
    textDecorationLine: 'underline',
  },
  linkSmall: {
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    fontWeight: 500,
    letterSpacing: '-0.006em',
    fontFeatureSettings: `'tnum' on, 'lnum' on, 'cv05' on`,
    textDecorationLine: 'underline',
  },
  legal: {
    fontSize: '0.625rem',
    lineHeight: '0.75rem',
    fontWeight: 400,
    fontFeatureSettings: `'tnum' on, 'lnum' on, 'cv05' on`,
  },
  footer: {
    fontSize: '0.625rem',
    lineHeight: '1rem',
    fontWeight: 400,
  },
  stamp: {
    fontFamily: 'Dosis',
    fontStyle: 'normal',
    lineHeight: '100%',
    textTransform: 'uppercase',
    letterSpacing: '0.03em',
  },
}
