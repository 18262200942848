import { useBalance } from 'wagmi'
import { useState } from 'react'
import { parseToBigNumber } from 'utils/web3'
import { LOW_GAS_TRESHOLD } from 'constants/config'

interface UseMaticBalanceParams {
  clientAddress?: string
}

export const useMaticBalance = ({ clientAddress }: UseMaticBalanceParams) => {
  const [isLowGas, setIsLowGas] = useState(false)

  const queryBalance = useBalance({
    addressOrName: clientAddress,
    enabled: !!clientAddress,
    watch: false,
    onSuccess: (data) => {
      const base = parseToBigNumber(LOW_GAS_TRESHOLD)

      if (data.value.lte(base)) {
        return setIsLowGas(true)
      }
      return setIsLowGas(false)
    },
  })

  return { ...queryBalance, isLowGas }
}
