import { useCallback } from 'react'
import { useToast as useChakraToast } from '@chakra-ui/react'

import Toast, { ToastProps } from 'components/Toast'

const TOAST_DURATION = 8000

type RenderCustomToastParams = Omit<ToastProps, 'onClose'>

export const useToast = () => {
  const toast = useChakraToast()

  const showToast = useCallback(
    ({ type, header, message }: RenderCustomToastParams) =>
      toast({
        duration: TOAST_DURATION,
        isClosable: true,
        position: 'bottom',
        render: ({ onClose }) => (
          <Toast
            type={type}
            onClose={onClose}
            header={header}
            message={message}
          />
        ),
      }),
    [toast],
  )
  return { showToast }
}
