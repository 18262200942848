import { Alert } from './Alert'
import { Avatar } from './Avatar'
import { Button } from './Button'
import { Checkbox } from './Checkbox'
import { Divider } from './Divider'
import { Drawer } from './Drawer'
import { InlineMessage } from './InlineMessage'
import { Input } from './Input'
import { LanguageSelector } from './LanguageSelector'
import { Link } from './Link'
import { Menu } from './Menu'
import { Progress } from './Progress'
import { Radio } from './Radio'
import { Select } from './Select'
import { Stat } from './Stat'
import { Steps } from './Steps'
import { Switch } from './Switch'
import { Table } from './Table'
import { Tabs } from './Tabs'
import { Toast } from './Toast'
import { Tooltip } from './Tooltip'

export const components = {
  Avatar,
  Button,
  Checkbox,
  Divider,
  Drawer,
  InlineMessage,
  Input,
  LanguageSelector,
  Link,
  Menu,
  Progress,
  Radio,
  Select,
  Stat,
  Steps,
  Switch,
  Table,
  Tabs,
  Toast,
  Tooltip,
}
