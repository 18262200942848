import alerts from './alertsMS.json'
import common from './commonMS.json'
import errors from './errorsMS.json'
import header from './headerMS.json'
import help from './helpMS.json'
import main from './mainMS.json'
import redemption from './redemptionMS.json'
import subheader from './subheaderMS.json'
import toasts from './toastsMS.json'

export const translationsMS = {
  header,
  alerts,
  common,
  errors,
  help,
  main,
  redemption,
  toasts,
  subheader,
}
