import axios, { AxiosRequestTransformer, AxiosResponseTransformer } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

// Create the api service which operational needs calls
const MaticService = axios.create({
  transformRequest: [
    (data) => (data ? snakecaseKeys(data, { deep: true }) : data),
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],

  transformResponse: [
    ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
    (data) => (data ? camelcaseKeys(data, { deep: true }) : data),
  ],
})

export default MaticService
