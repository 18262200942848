import TokenCard from 'features/debugger/components/TokenCard'
import DebugTemplate from 'features/debugger/templates/DebugTemplate'
import { ListingProps } from 'features/debugger/types'

const TokenListing = ({ tokens, clientAddress }: ListingProps) => {
  return (
    <DebugTemplate title="Tokens">
      {tokens.map((token) => (
        <TokenCard key={token} token={token} clientAddress={clientAddress} />
      ))}
    </DebugTemplate>
  )
}

export default TokenListing
